<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :loading="pending" :title="$t('Edit User')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <SearchSelectClient
                      v-model="client_id"
                      rules="required"
                      :client="user.client.contact_handle"
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="role"
                      rules="required"
                      label="Role"
                      :items="roles"
                      menu-props="offset-y"
                      placeholder="Select Role"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="firstname"
                      rules="required"
                      label="Firstname"
                      name="firstname"
                      placeholder="Enter Firstname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="lastname"
                      rules="required"
                      label="Lastname"
                      name="lastname"
                      placeholder="Enter Lastname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="username"
                      rules="required"
                      label="Username"
                      name="username"
                      placeholder="Enter Username"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="password"
                      type="password"
                      label="Password"
                      name="password"
                      placeholder="Enter Password"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="email"
                      type="email"
                      rules="required|email"
                      label="E-Mail"
                      name="email"
                      placeholder="Enter E-Mail"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { roles } from '@/config'
import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'EditUser',
  components: {
    AppCard,
    ItemWrapper,
    SearchSelectClient,
    VSelectWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Users')

    // store
    const user = computed(_ => $store.state.user.user)
    const pending = computed(_ => $store.state.user.pending)
    const pendingSave = computed(_ => $store.state.user.pendingSave)
    const errors = computed(_ => $store.state.user.errors)
    const foundClients = computed(_ => $store.state.client.foundClients)
    const editUser = _ => $store.dispatch('user/editUser')
    const getEditUser = _ => $store.dispatch('user/getEditUser')
    const clearUser = _ => $store.dispatch('user/clearUser')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getEditUser()

    const submitForm = _ => {
      if (pendingSave.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editUser().then(({ user, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Nutzer erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'users-item',
            params: { id: user.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearUser()
    })

    return {
      user,
      prevRoute,
      roles,
      pending,
      pendingSave,
      foundClients,
      errors,
      submitForm,
      ...mapFields('user', [
        'user.firstname',
        'user.lastname',
        'user.username',
        'user.password',
        'user.email',
        'user.role',
        'user.client_id',
      ]),
    }
  },
})
</script>
